import React from "react";
import { ButtonLink } from "components/common/Button";
import Container from "components/common/Container";
import styles from "./SectionAnchors.module.css";

export interface SectionAnchor {
  href: string;
  title: string;
}

interface SectionAnchorProps {
  anchors: SectionAnchor[];
}

const SectionAnchors = ({ anchors }: SectionAnchorProps) => {
  return (
    <Container>
      <div className={styles.anchors}>
        {anchors.map(anchor => {
          return (
            <ButtonLink href={`#${anchor.href}`} key={anchor.href} theme="secondary">
              {anchor.title}
            </ButtonLink>
          );
        })}
      </div>
    </Container>
  );
};

export default SectionAnchors;
