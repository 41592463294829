import { gql } from "@uplift-ltd/apollo";

export { REDIRECTION_RULES_QUERY } from "hooks/useServerSideRedirects";

export type {
  StaticPageQueryQuery as PageQuery,
  StaticPageQueryVariables as PageQueryVariables,
  StaticPageQueryPage as StaticPage,
  PageInfoHeroSection as HeroSection,
} from "./__generated__/queries";

const PAGE_FRAGMENT = gql`
  fragment PageInfo on Page {
    id
    content
    title
    slug
    seo {
      title
      metaDesc
      metaKeywords
      canonical
      opengraphImage {
        id
        mediaItemUrl
      }
    }
    heroSection {
      title
      summary
    }
    featuredImage {
      node {
        mediaItemUrl
        id
        mediaDetails {
          height
          width
        }
      }
    }
  }
`;

// Cache once for all users on backend
export const STATIC_PAGE_QUERY = gql`
  ${PAGE_FRAGMENT}
  query StaticPageQuery($id: ID!, $idType: PageIdType!) {
    page(id: $id, idType: $idType) {
      id
      ...PageInfo
    }
  }
`;

// Cache per user on backend
export const USER_PAGE_QUERY = gql`
  ${PAGE_FRAGMENT}
  query UserPageQuery($id: ID!, $idType: PageIdType!) {
    page(id: $id, idType: $idType) {
      id
      ...PageInfo
    }
  }
`;
