import { safeJoinWithEmDash } from "@uplift-ltd/strings";
import React from "react";
import { EventFieldsFragmentFragment as EventFields } from "components/events/__generated__/queries";
import { SECONDARY_TIMEZONE_NAME, WORDPRESS_TIMEZONE_NAME } from "constants/date";
import { formatEasternTime, formatPacificTime } from "./utils";
import styles from "./Event.module.css";

interface EventProps {
  event: {
    eventFields?: Pick<EventFields, "eventStartDateAndTime" | "eventEndTime"> | null;
  };
}

const EventTimes = ({ event }: EventProps) => {
  const eventStartDate = event.eventFields?.eventStartDateAndTime;
  const eventEndTime = event.eventFields?.eventEndTime;

  return (
    <span>
      {safeJoinWithEmDash(
        formatPacificTime(eventStartDate),
        eventEndTime && formatPacificTime(eventStartDate, eventEndTime)
      )}
      {` ${WORDPRESS_TIMEZONE_NAME} / `}
      {safeJoinWithEmDash(
        formatEasternTime(eventStartDate),
        eventEndTime && formatEasternTime(eventStartDate, eventEndTime)
      )}{" "}
      {SECONDARY_TIMEZONE_NAME}
    </span>
  );
};

export const EventStartTimes = ({ event }: EventProps) => {
  const eventStartDate = event.eventFields?.eventStartDateAndTime;

  return (
    <div>
      <span className={styles.noWrap}>
        {formatPacificTime(eventStartDate)} {WORDPRESS_TIMEZONE_NAME}
      </span>
      <span> / </span>
      <span className={styles.noWrap}>
        {formatEasternTime(eventStartDate)} {SECONDARY_TIMEZONE_NAME}
      </span>
    </div>
  );
};

export default EventTimes;
