import { faArrowRight, faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ButtonLink } from "components/common/Button";
import { ESSENTIAL_CLINICAL_FORMS_URL, HIPAA_COMPLIANCE_KIT } from "constants/urls";
import styles from "./PrivatePracticeTools.module.css";

const PrivatePracticeTools = () => (
  <section className={styles.sectionContainer}>
    <div className={styles.sectionTitle}>
      <FontAwesomeIcon className="mr-2" icon={faInbox} />
      Private Practice Forms
    </div>
    <div className={styles.formGroup}>
      <ButtonLink href={ESSENTIAL_CLINICAL_FORMS_URL} className={styles.button}>
        Essential Clinical Forms <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
      </ButtonLink>
      <ButtonLink href={HIPAA_COMPLIANCE_KIT} className={styles.button}>
        HIPAA Compliance Kit <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
      </ButtonLink>
    </div>
  </section>
);

export default PrivatePracticeTools;
