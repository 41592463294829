import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import React from "react";
import Link from "components/common/Link";
import { COURSE_AUTHOR_URL } from "constants/urls";
import { CourseAuthor, getAuthorNameWithTitle } from "helpers/courseDetails";

export interface InlineAuthorsProps {
  className?: string;
  linkClassName?: string;
  authors: CourseAuthor[];
}

const InlineAuthors = ({ authors, className, linkClassName }: InlineAuthorsProps) => {
  return (
    <span className={className}>
      {authors?.filter(notEmpty).map((author, index) => (
        <React.Fragment key={author.id}>
          <Link
            className={linkClassName}
            href={makeUrl(COURSE_AUTHOR_URL, { slug: author.slug as string })}
          >
            {getAuthorNameWithTitle(author)}
          </Link>
          {index !== authors.length - 1 ? ", " : null}
        </React.Fragment>
      ))}
    </span>
  );
};

export default InlineAuthors;
