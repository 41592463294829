import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import React from "react";
import Link from "components/common/Link";
import { BundledWebinars } from "components/events/Events";
import { EventsQueryNode as Event } from "components/events/__generated__/queries";
import { useEventsList } from "components/events/queries";
import { formatShortDate } from "components/events/utils";
import { EVENT_URL, EVENTS_URL } from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import styles from "./Events.module.css";

export default function EventsHome() {
  const { data } = useEventsList(true);
  const events = mapNodes(data?.events);

  return events && !!events.length ? (
    <section className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>
        <Link href={EVENTS_URL} className={styles.sectionTitleLink}>
          Webinars/
          <wbr />
          Trainings
        </Link>
      </div>
      <div>
        {events
          .sort((a: Event, b: Event) => {
            return (
              a.eventFields?.eventStartDateAndTime?.localeCompare(
                b.eventFields?.eventStartDateAndTime || ""
              ) || 0
            );
          })
          .slice(0, 4)
          .map(event => (
            <div className={styles.eventContainer} key={event.id}>
              <div className={styles.eventDate}>
                {event.eventFields?.eventStartDateAndTime && (
                  <>
                    <span className={styles.eventBullet}>&#9632;</span>{" "}
                    <span className={styles.eventDateShort}>
                      {formatShortDate(event.eventFields?.eventStartDateAndTime, "short")}
                    </span>
                    <span className={styles.eventDateLong}>
                      {formatShortDate(event.eventFields?.eventStartDateAndTime, "long")}
                    </span>
                  </>
                )}
              </div>
              <div className={styles.eventText}>
                <Link
                  key={event.id}
                  href={makeUrl(EVENT_URL, { slug: event.slug as string })}
                  className={styles.eventLink}
                >
                  {event.eventFields?.eventShortTitle || event.title}
                </Link>
                <div className={styles.eventExcerpt}>{event.eventFields?.eventExcerpt}</div>
                {event.eventFields?.bundledWebinars && (
                  <BundledWebinars
                    bundledWebinars={event.eventFields.bundledWebinars.filter(notEmpty)}
                    shortForm
                  />
                )}
              </div>
            </div>
          ))}
        {events.length > 4 && (
          <div className={styles.eventContainer}>
            <div className={styles.eventDate} />
            <div className={styles.eventText}>
              <Link href={EVENTS_URL} className={styles.eventLink}>
                See more...
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  ) : null;
}
