import cx from "classnames";
import Image from "next/image";
import React from "react";
import InlineAuthors from "components/courseAuthor/InlineAuthors";
import { CourseAuthor } from "helpers/courseDetails";
import styles from "./Avatar.module.css";

type AvatarSize = "sm" | "lg";
type TextSize = "sm" | "lg";

const DEFAULT_SIZE = "sm";
const DEFAULT_TEXT_SIZE = "sm";

type AvatarImg = {
  mediaItemUrl?: string | null;
  altText?: string | null;
};

interface AvatarProps {
  size?: AvatarSize;
  textSize?: TextSize;
  img?: AvatarImg;
  name?: string | null;
  extended?: boolean;
  authors?: CourseAuthor[];
  initials?: string;
  initialsClassName?: string;
}

export default function Avatar({
  size = DEFAULT_SIZE,
  textSize = DEFAULT_TEXT_SIZE,
  img = undefined,
  name = null,
  extended = false,
  authors = [],
  initials = undefined,
  initialsClassName = undefined,
}: AvatarProps) {
  return (
    <div className={styles.root}>
      {img?.mediaItemUrl ? (
        <div className={cx(styles.avatarImageContainer, styles[size])}>
          <Image
            className={styles.avatarImage}
            alt={img.altText as string}
            src={img.mediaItemUrl}
            layout="fill"
            objectFit="cover"
          />
        </div>
      ) : (
        initials && (
          <div className={cx(styles.avatarImageContainer, styles[size])}>
            <div className={cx(styles.avatarInitials, initialsClassName)}>
              <span className={styles.avatarInitialsInner}>{initials}</span>
            </div>
          </div>
        )
      )}
      {extended && (
        <div className={cx(styles.avatarText, styles[textSize])}>
          {name && <>Presented by {name}</>}
          {authors.length ? (
            <>
              Developed by <InlineAuthors authors={authors} linkClassName={styles.authorLink} />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}
