import { useEnhancedQuery } from "@uplift-ltd/apollo";
import React from "react";
import { PageIdType } from "__generated__/globalTypes";
import { StaticPageQuery, StaticPageQueryVariables } from "components/StaticPageTemplate";
import { STATIC_PAGE_QUERY } from "components/StaticPageTemplate/queries";
import Container from "components/common/Container";
import { FullPageLoading } from "components/common/Loading";
import { HOME_URL } from "constants/urls";
import { usePageMeta } from "hooks/usePageMeta";
import CeRequirementsBox from "./CeRequirementsBox";
import CertificatePrograms from "./CertificatePrograms";
import Hero from "./Hero";
import PrivatePracticeTools from "./PrivatePracticeTools";
import SavingsPackagesHome from "./SavingsPackages";

const HeroData = {
  title: "Online Continuing Education for Mental Health Professionals",
  subtitle:
    "Over 120 unique courses for Psychologists, " +
    "Marriage & Family Therapists, Social Workers, " +
    "Professional Counselors, & other professionals.",
};

const Home = ({ title }: { title: string }) => {
  const { data: pageData, loading: pageLoading } = useEnhancedQuery<
    StaticPageQuery,
    StaticPageQueryVariables
  >(STATIC_PAGE_QUERY, {
    variables: { id: HOME_URL, idType: PageIdType.URI },
  });

  const staticPage = pageData?.page;
  usePageMeta(title || staticPage?.seo?.title, staticPage?.seo);

  if (pageLoading || !staticPage) {
    return <FullPageLoading />;
  }

  return (
    <Container>
      <Hero title={HeroData.title} subtitle={HeroData.subtitle} />
      <CeRequirementsBox />
      <CertificatePrograms />
      <PrivatePracticeTools />
      <SavingsPackagesHome />
    </Container>
  );
};

export default Home;
