import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import React from "react";
import Link from "components/common/Link";
import { BUY_TOKENS_URL, PRE_LICENSING_COURSES_URL, SAVING_PACKAGES_URL } from "constants/urls";
import SavingsPackageImg from "images/savings-packages.png";
import styles from "./SavingsPackages.module.css";

const SavingsPackagesHome = () => (
  <section className={styles.mainWrapper}>
    <div className={styles.container}>
      <div className={styles.colText}>
        <div className={styles.title}>
          <FontAwesomeIcon className="mr-2" icon={faBookOpen} />
          Course Savings Packages
        </div>
        <div className={styles.subTitle}>
          Zur Institute offers special, high-quality, and exceptionally affordable savings packages
          for Psychologists, LMFTs, SWs, LPCs and other mental health professionals.
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linksItem}>
            <Link href={PRE_LICENSING_COURSES_URL}>
              Pre-Licensing Courses & Savings Packages of Required Courses
            </Link>
          </div>
          <div className={styles.linksItem}>
            <Link href={SAVING_PACKAGES_URL}>Special Savings Packages</Link>{" "}
            <span className="text-base font-normal no-underline">
              - Supervision & Dual Relationships
            </span>
          </div>

          <div className={styles.linksItem}>
            <Link href={BUY_TOKENS_URL}>Tokens</Link>{" "}
            <span className="text-base font-normal no-underline">
              - Create Your Own, Bundle and Save!
            </span>
          </div>
        </div>
      </div>
      <div className={styles.colImg}>
        <Image src={SavingsPackageImg} width="379" height="287" />
      </div>
    </div>
  </section>
);

export default SavingsPackagesHome;
