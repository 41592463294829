import { useEnhancedQuery } from "@uplift-ltd/apollo";
import { makeUrl } from "@uplift-ltd/strings";
import { notEmpty } from "@uplift-ltd/ts-helpers";
import React from "react";
import Avatar from "components/common/Avatar";
import Container from "components/common/Container";
import Link from "components/common/Link";
import Loading from "components/common/Loading";
import { IndividualSectionHeader } from "components/common/SectionHeader";
import SectionAnchors, { SectionAnchor } from "components/common/SectionHeader/SectionAnchors";
import { EventStartTimes } from "components/events/EventTimes";
import {
  EventFieldsFragmentBundledWebinars as BundledWebinar,
  EventInfoFragment as EventInfo,
  EventSectionsQueryQuery as EventSectionsQuery,
  EventsQueryNode as EventsQueryEvent,
  EventsQueryQuery as EventsQuery,
} from "components/events/__generated__/queries";
import { EVENT_URL } from "constants/urls";
import { mapNodes } from "helpers/mapNodes";
import { PageMeta, usePageMeta } from "hooks/usePageMeta";
import { EVENT_SECTIONS_QUERY, EVENTS_QUERY, useEventsList } from "./queries";
import { formatShortDate } from "./utils";
import styles from "./Events.module.css";

export { EVENTS_QUERY };

export type { EventsQuery };

const renderDate = (dateString?: string | null) => {
  const formattedDate = formatShortDate(dateString);

  return <div>{formattedDate}</div>;
};

const sortWebinarsByDate = (a: BundledWebinar, b: BundledWebinar) => {
  const aDate = a.eventFields?.eventStartDateAndTime;
  const bDate = b.eventFields?.eventStartDateAndTime;

  if (!aDate || !bDate) {
    return 0;
  }

  return new Date(aDate).getTime() - new Date(bDate).getTime();
};

const BundledWebinarItem = ({
  webinar,
  shortForm,
}: {
  webinar: BundledWebinar;
  shortForm: boolean;
}) => {
  const date = formatShortDate(webinar.eventFields?.eventStartDateAndTime, "short");

  return (
    <div className={styles.bundledWebinar}>
      <div className={styles.bundledWebinarBullet}>&#9632;</div>
      {!shortForm && <div className={styles.bundledWebinarDate}>{date}</div>}
      <div className={styles.bundledWebinarTitle}>
        {shortForm ? webinar.eventFields?.eventShortTitle : webinar.title}
      </div>
    </div>
  );
};

export const BundledWebinars = ({
  bundledWebinars,
  shortForm,
}: {
  bundledWebinars: BundledWebinar[];
  shortForm: boolean;
}) => {
  return (
    <div className={styles.bundledWebinars}>
      {bundledWebinars.sort(sortWebinarsByDate).map(bundledWebinar => (
        <BundledWebinarItem
          key={bundledWebinar.id}
          webinar={bundledWebinar}
          shortForm={shortForm}
        />
      ))}
    </div>
  );
};

export const EventPreview = ({ event }: { event: EventsQueryEvent }) => {
  const presenterImage = event.eventFields?.presenterImage;
  const bundledWebinars = event.eventFields?.bundledWebinars?.filter(notEmpty);

  return (
    <Link key={event.id} href={makeUrl(EVENT_URL, { slug: event.slug as string })}>
      <div className={styles.topRow}>
        {presenterImage?.mediaItemUrl && (
          <div className={styles.eventAvatarContainer}>
            <Avatar size="lg" img={presenterImage} authors={[]} />
          </div>
        )}
        <div>
          <div className={styles.eventDate}>
            {renderDate(event.eventFields?.eventStartDateAndTime)}
          </div>
          <div className={styles.eventTime}>
            <EventStartTimes event={event} />
          </div>
          <div className={styles.eventPresenter}>{event.eventFields?.presenterName}</div>
        </div>
      </div>
      <div className={styles.eventInfoContainer}>
        <div className={styles.eventTitle}>{event.title}</div>
        <div className={styles.eventExcerpt}>{event.eventFields?.eventExcerpt}</div>
        {bundledWebinars && <BundledWebinars bundledWebinars={bundledWebinars} shortForm={false} />}
      </div>
    </Link>
  );
};

interface EventsProps {
  events: EventInfo[];
  layout?: "grid" | "rows";
}

export const EventsList = ({ events, layout = "grid" }: EventsProps) => (
  <>
    {events.length > 0 ? (
      <div className={layout === "rows" ? styles.eventWrapperRows : styles.eventWrapper}>
        {events.map(event => (
          <div key={event.id} className={styles.eventContainer}>
            <EventPreview event={event} />
          </div>
        ))}
      </div>
    ) : (
      <p className="text-center">No events are currently available.</p>
    )}
  </>
);

interface EventsPageProps {
  title: string;
  seo?: PageMeta | null;
}

const EventsPage = ({ title, seo }: EventsPageProps) => {
  usePageMeta(title, seo);
  const { data, loading: eventsLoading } = useEventsList(true);
  const events = mapNodes(data?.events);
  const { data: sectionsData, loading: sectionsLoading } =
    useEnhancedQuery<EventSectionsQuery>(EVENT_SECTIONS_QUERY);

  if (eventsLoading || sectionsLoading || !data) return <Loading />;

  const sections = sectionsData?.eventSections?.eventSections?.sections?.filter(notEmpty) || [];
  const anchors = sections.map((section): SectionAnchor => {
    return {
      title: section.sectionTitle || "",
      href: section.group || "",
    };
  });

  return (
    <>
      <SectionAnchors anchors={anchors} />
      {sections.map(section => {
        return (
          <div key={section.group}>
            <IndividualSectionHeader
              title={section.sectionTitle}
              summary={section.sectionDescription}
              id={section.group}
            />
            <Container>
              <EventsList
                events={events.filter(event => event?.eventFields?.eventGroup === section.group)}
              />
            </Container>
          </div>
        );
      })}
    </>
  );
};

export default EventsPage;
