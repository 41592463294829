import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import React from "react";
import Link from "components/common/Link";
import { useCertificatePrograms } from "hooks/useCertificatePrograms";
import ImgSrc from "images/zur-cert-programs.png";
import styles from "./CertificatePrograms.module.css";

const CertificatePrograms = () => {
  const certificatePrograms = useCertificatePrograms();

  return (
    <section className={styles.mainWrapper}>
      <div className={styles.container}>
        <div className={styles.colText}>
          <div className={styles.title}>
            <FontAwesomeIcon className="mr-2" icon={faGraduationCap} />
            Online CE Course Certificate Programs
          </div>
          <div className={styles.subTitle}>
            As one of the first online CE programs, Zur Institute offers unique courses and
            perspectives that are rarely offered elsewhere. Zur Institute offers the following
            certificate programs:
          </div>
          <div className={styles.links}>
            {certificatePrograms.map(program => (
              <Link href={program.catalogPath} key={program.slug}>
                {program.name}
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.colImg}>
          <Image src={ImgSrc} width="379" height="287" />
        </div>
      </div>
    </section>
  );
};

export default CertificatePrograms;
